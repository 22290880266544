const icons = {
    'ic_videos': require('@icons/navigation/ic_videos.svg').default,
    'locked': require('@icons/navigation/ic_locked.svg').default,
    'ic_zin-play-app': require('@icons/navigation/ic_zin-play-app.svg').default,
    'ic_choreo-notes': require('@icons/navigation/ic_choreo-notes.svg').default,
    'ic_cd': require('@icons/navigation/ic_cd.svg').default,
    'ic_schedule': require('@icons/navigation/ic_schedule.svg').default,
    'ic_package': require('@icons/navigation/ic_package.svg').default,
    'ic_events': require('@icons/navigation/ic_events.svg').default,
    'ic_community': require('@icons/navigation/ic_community.svg').default,
    'ic_form': require('@icons/navigation/ic_form.svg').default,
    'ic_settings': require('@icons/navigation/ic_settings.svg').default,
    'ic_classbuzz': require('@icons/navigation/ic_classbuzz.svg').default,
    'ic_materials': require('@icons/navigation/ic_materials.svg').default,
    'ic_bullhorn': require('@icons/navigation/ic_bullhorn.svg').default,
    'ic_certificate': require('@icons/navigation/ic_certificate.svg').default,
    'ic_trainings': require('@icons/navigation/ic_trainings.svg').default,
    'ic_zin-jams': require('@icons/navigation/ic_zin-jams.svg').default,
    'ic_e-learning': require('@icons/navigation/ic_e-learning.svg').default,
    'ic_dumbbell': require('@icons/navigation/ic_dumbbell.svg').default,
    'ic_circl': require('@icons/navigation/ic_circl.svg').default,
    'ic_coaching': require('@icons/navigation/ic_coaching.svg').default,
    'ic_group': require('@icons/navigation/ic_group.svg').default,
    'ic_academy': require('@icons/navigation/ic_academy.svg').default,
    'ic_phone': require('@icons/navigation/ic_phone.svg').default,
    'ic_money': require('@icons/navigation/ic_money.svg').default,
    'ic_shirt': require('@icons/navigation/ic_shirt.svg').default,
    'ic_share': require('@icons/navigation/ic_share.svg').default,
    'ic_posts': require('@icons/navigation/ic_posts.svg').default,
    'ic_volunteer': require('@icons/navigation/ic_volunteer.svg').default,
    'ic_mentor': require('@icons/navigation/ic_mentor.svg').default,
    'ic_support': require('@icons/navigation/ic_support.svg').default,
    'ic_leader': require('@icons/navigation/ic_leader.svg').default,
    'ic_trophy': require('@icons/navigation/ic_trophy.svg').default,
    'ic_status': require('@icons/navigation/ic_status.svg').default,
    'ic_attendance': require('@icons/navigation/ic_attendance.svg').default,
    'ic_pointer': require('@icons/navigation/ic_pointer.svg').default,
    'ic_request-form': require('@icons/navigation/ic_request-form.svg').default,
    'ic_registration': require('@icons/navigation/ic_registration.svg').default,
    'ic_docs': require('@icons/navigation/ic_docs.svg').default,
    'ic_material-request': require('@icons/navigation/ic_material-request.svg').default,
    'ic_license': require('@icons/navigation/ic_license.svg').default,
    'ic_manage': require('@icons/navigation/ic_manage.svg').default,
    'ic_toolkit': require('@icons/navigation/ic_toolkit.svg').default,
    'ic_mix-breakdowns': require('@icons/navigation/ic_mix-breakdowns.svg').default,
};
export default icons;
