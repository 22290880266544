import { defineComponent, ref, computed } from 'vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
import { theme, ThemeType } from "@ts/Util/theme";
export default defineComponent({
    name: 'NavTooltip',
    props: {
        hasLink: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const visible = ref(false);
        const isMobile = isMobileReactive();
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        const tooltipStyle = computed(() => {
            let bottomOffset = 0;
            let leftOffset = 0;
            if (!isMobile.value) {
                leftOffset = 8;
                bottomOffset = props.content.length > 90 ? -3 : -2;
            }
            else {
                leftOffset = 11;
                bottomOffset = props.content.length > 90 ? -2.4 : -1.2;
            }
            return {
                bottom: `calc(var(--offset-distance) * ${bottomOffset})`,
                left: `${leftOffset}rem`,
            };
        });
        const handleVisibility = (e, visibility) => {
            if (!isMobile.value) {
                visible.value = visibility;
            }
            else if (isMobile.value) {
                visible.value = !visible.value;
            }
        };
        return {
            visible,
            tooltipStyle,
            isMobile,
            isZumbaThemed,
            handleVisibility,
        };
    },
});
