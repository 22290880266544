import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: "tooltip-trigger",
        onMouseenter: _cache[0] || (_cache[0] = () => _ctx.handleVisibility('moveenter', true)),
        onMouseleave: _cache[1] || (_cache[1] = () => _ctx.handleVisibility('moveleave', false))
    }, [
        (_ctx.$slots['trigger'])
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
            ]))
            : _createCommentVNode("", true),
        (_ctx.content.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["tooltip-content", [{ visible: _ctx.visible }]]),
                style: _normalizeStyle(_ctx.tooltipStyle)
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["z-b5", { 'f-inter': _ctx.isZumbaThemed }]),
                    innerHTML: _ctx.content
                }, null, 10, _hoisted_2)
            ], 6))
            : _createCommentVNode("", true)
    ], 32));
}
