import { computed } from "vue";
import { theme, ThemeType } from "@ts/Util/theme";
import { readPageData } from '@ts/Util/page';
const MAX_RECENT_ACTIVITY = 3;
const LOCAL_STORAGE_ZUMBA_KEY = 'nav-menu-recent-activity-zumba';
const LOCAL_STORAGE_STRONG_KEY = 'nav-menu-recent-activity-strong';
var Sources;
(function (Sources) {
    Sources["localStorage"] = "local-storage";
})(Sources || (Sources = {}));
const SOURCE = Sources.localStorage;
const isZumbaThemed = theme.value === ThemeType.Zumba;
export const recentActivity = computed(() => getRecentActivity());
const localStorageKey = computed(() => {
    const userPID = readPageData('userPID');
    let localStorageKey = isZumbaThemed ? LOCAL_STORAGE_ZUMBA_KEY : LOCAL_STORAGE_STRONG_KEY;
    if (userPID) {
        localStorageKey += `-${userPID}`;
    }
    return localStorageKey;
});
const getRecentActivity = () => {
    let recentActivity = [];
    switch (SOURCE) {
        case Sources.localStorage:
            recentActivity = JSON.parse(localStorage.getItem(localStorageKey.value) || '[]');
            break;
    }
    return recentActivity;
};
export const saveDefaultActivity = (links) => {
    saveToSource(links);
};
export const saveRecentActivity = (link) => {
    let newRecentActivity = getRecentActivity();
    const targetIndex = newRecentActivity.findIndex((item) => item.id === link.id);
    if (targetIndex === -1) {
        if (newRecentActivity.length >= MAX_RECENT_ACTIVITY) {
            newRecentActivity.pop();
        }
    }
    else {
        newRecentActivity.splice(targetIndex, 1);
    }
    newRecentActivity.unshift(link);
    saveToSource(newRecentActivity);
};
export const saveToSource = (newRecentActivity) => {
    switch (SOURCE) {
        case Sources.localStorage:
            localStorage.setItem(localStorageKey.value, JSON.stringify(newRecentActivity));
            break;
    }
};
