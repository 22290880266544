import { ref, watchEffect, markRaw } from 'vue';
import icons from '@ts/Navigation/icons';
export function useIconLoader(iconName) {
    const iconSvg = ref(null);
    const loadIcon = () => {
        try {
            if (iconName) {
                const iconComponent = icons[iconName];
                if (iconComponent) {
                    iconSvg.value = markRaw(iconComponent);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    watchEffect(() => {
        loadIcon();
    });
    return {
        iconSvg,
        loadIcon
    };
}
