import { defineComponent } from "vue";
import LoadingIcon from '@bx-icons/regular/bx-loader.svg';
export default defineComponent({
    name: 'Loading',
    components: {
        LoadingIcon,
    },
    props: {
        height: {
            type: String,
            default: "15rem"
        },
        theme: {
            type: String,
            default: 'dark',
            validator: (value) => ['dark', 'light'].includes(value),
        },
    }
});
