import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingIcon = _resolveComponent("LoadingIcon");
    return (_openBlock(), _createElementBlock("div", {
        class: "loading",
        style: _normalizeStyle({
            height: _ctx.height
        })
    }, [
        _createVNode(_component_LoadingIcon, {
            class: _normalizeClass(["loading-spinner", { 'light': _ctx.theme == 'light' }])
        }, null, 8, ["class"])
    ], 4));
}
