import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "action-item" };
const _hoisted_2 = ["href"];
const _hoisted_3 = { class: "action-icon-container" };
import { useIconLoader } from '@ts/Navigation/useIconLoader';
import { theme, ThemeType } from "@ts/Util/theme";
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavRecentActivityItem',
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        href: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        attributes: {
            type: Object,
            required: true
        }
    },
    emits: ['clicked'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        const emit = __emit;
        const { iconSvg } = useIconLoader(props.icon);
        const handleClick = () => {
            emit('clicked', props);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("a", _mergeProps({
                    href: __props.href,
                    class: "z-b4"
                }, __props.attributes, { onClick: handleClick }), [
                    _createElementVNode("div", _hoisted_3, [
                        (_unref(iconSvg))
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(iconSvg)), {
                                key: 0,
                                class: "action-icon"
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(["z-b6 action-label", { 'f-inter': isZumbaThemed }])
                    }, _toDisplayString(__props.title), 3)
                ], 16, _hoisted_2)
            ]));
        };
    }
});
