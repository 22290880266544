import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "recents-container" };
import { computed, onMounted } from 'vue';
import { validator } from "@ts/Util/i18n";
import NavRecentActivityItem from '@components/Navigation/NavRecentActivityItem.vue';
import { theme, ThemeType } from "@ts/Util/theme";
import { saveDefaultActivity } from '@ts/Navigation/recent-activity';
import { isMobile } from '@ts/Util/responsiveness';
const localT = {
    recents: 'Recents'
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavRecentActivity',
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: validator(localT)
        },
        items: {
            type: Array,
            default: () => []
        },
        defaultItems: {
            type: Array,
            required: true
        }
    },
    emits: ['clicked'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        const emit = __emit;
        onMounted(() => {
            if (props.items.length === 0) {
                saveDefaultActivity(props.defaultItems);
            }
        });
        const recentActivity = computed(() => props.items.length > 0 ? props.items : props.defaultItems);
        return (_ctx, _cache) => {
            return (recentActivity.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["nav-recent-activity", { 'mobile': _unref(isMobile) }])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(["z-b6 recents", { 'f-inter': isZumbaThemed }])
                    }, _toDisplayString(__props.t.recents), 3),
                    _createElementVNode("div", _hoisted_1, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recentActivity.value, (item) => {
                            return (_openBlock(), _createBlock(NavRecentActivityItem, {
                                key: item.id,
                                id: item.id,
                                icon: item.icon,
                                title: item.title,
                                href: item.href,
                                locked: item.locked,
                                attributes: item.attributes,
                                onClicked: _cache[0] || (_cache[0] = (link) => emit('clicked', link))
                            }, null, 8, ["id", "icon", "title", "href", "locked", "attributes"]));
                        }), 128))
                    ])
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
